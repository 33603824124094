import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const NotFound = lazy(
  () => import('@/shared/components/NotFound'),
);
const ProductConfiguration = lazy(
  () => import('@/containers/Views/Products/ProductConfiguration'),
);
const ProductCreate = lazy(
  () => import('@/containers/Views/Products/ProductCreate'),
);
const ProductEdit = lazy(
  () => import('@/containers/Views/Products/ProductEdit'),
);
const ProductPage = lazy(
  () => import('@/containers/Views/Products/ProductPage'),
);
const ProductPublish = lazy(
  () => import('@/containers/Views/Products/ProductPublish'),
);
const ProductRelations = lazy(
  () => import('@/containers/Views/Products/ProductRelations'),
);

const ProductsRouter = (
  <Route path="products">
    <Route
      index
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Find}
        >
          <ProductPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Create}
        >
          <ProductCreate />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/edit"
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Edit}
        >
          <ProductEdit />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/configuration"
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Edit}
        >
          <ProductConfiguration />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/publish"
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Edit}
        >
          <ProductPublish />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/relations"
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Edit}
        >
          <ProductRelations />
        </PrivateRoute>
      )}
    />
    <Route 
      path="not_found"
      element={(
        <PrivateRoute 
          pageName={EPage.Products} 
          permissions={EBasicActions.Find}
        >
          <NotFound scope="product" />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default ProductsRouter;
